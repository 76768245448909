import React from "react";

const ChatBotMessage = ({ message }) => {
  const downloadFile = (url, filename = "") => {
    if (filename.length === 0) filename = url.split("/").pop();
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: req.getResponseHeader("Content-Type"),
      });

      const isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || window.webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };
  return (
    <div
      className={
        message.sender === "user" ? "catbootContener rtl" : "catbootContener"
      }
    >
      <div
        className={
          message.sender === "user"
            ? "react-chatbot-kit-chat-bot-message-container-root rtl"
            : "react-chatbot-kit-chat-bot-message-container-root"
        }
      >
        {message.sender === "user" ? (
          <div
            className={
              message.sender === "user"
                ? "react-chatbot-kit-user-avatar-container"
                : "react-chatbot-kit-chat-bot-avatar-container"
            }
          ></div>
        ) : (
          ""
        )}
        <div
          className={
            message.sender === "user"
              ? "chatbot-message-user"
              : "chatbot-message"
          }
        >
          {
            // check if message is loading
            message.loading ? (
              <p className="loading"></p>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  //replace all /n with <br/>
                  __html:
                    message.isWelcomeMessage === true
                      ? message.message
                      : message.message.replace(/\n/g, "<br/>"),
                }}
              />
            )
          }
        </div>
        {message.sender !== "user" ? (
          <div
            className={
              message.sender === "user"
                ? "react-chatbot-kit-user-avatar-container"
                : "react-chatbot-kit-chat-bot-avatar-container"
            }
          ></div>
        ) : (
          ""
        )}
      </div>
      <div className="linksContener">
        <ul>
          {message?.links.map((link) => (
            <li className="card" onClick={() => downloadFile(link.link)}>
              {link.type === "mp4" ? (
                <video width="100%" height="100%" controls>
                  <source src={link.link} type="video/mp4" />
                </video>
              ) : link.type === "pdf" ? (
                <div className="pdf">
                  <img src="/pdf.png" alt="" width="20%" />
                  <br />
                  تحميل
                </div>
              ) : (
                ""
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ChatBotMessage;
