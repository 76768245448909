import React from "react";
import Button from "@material-ui/core/Button";
import { useEffect } from "react";

//defination  of Tools widget
//all the mathod define in actionprovider and all states of widget are passed in props
//you can use all fuunction and state with the help of props
const Tools = (props) => {
  const [links, setLinks] = React.useState([]);
  useEffect(() => {
    //get all the links from props.ToolsState.originalText and set in links array
    const links = props?.ToolsState?.originalText?.match(
      /https:\/\/apis.ircascms.com\/public\/[0-9]+_.pdf/gi
    );
    if (links) {
      setLinks(
        links.map((link) => ({
          type: link.split(".").pop(),
          link: link,
        }))
      );
    }
  }, [props]);
  const downloadFile = (url, filename = "") => {
    if (filename.length === 0) filename = url.split("/").pop();
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: req.getResponseHeader("Content-Type"),
      });

      const isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || window.webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  };
  return (
    <div className="tools" key={props.ToolsState.originalText}>
      <div
        //insert as html
        dangerouslySetInnerHTML={{ __html: props.ToolsState.originalText }}
      ></div>
      {/* <ul>
        {links.map((link) => (
          <li className="card" onClick={() => downloadFile(link.link)}>
            تحميل
            <br />
            {
              {
                pdf: "PDF",
                doc: "DOC",
                docx: "DOCX",
                xls: "XLS",
                xlsx: "XLSX",
                ppt: "PPT",
                pptx: "PPTX",
              }[link.type]
            }
          </li>
        ))}
      </ul> */}
    </div>
  );
};
export default Tools;
