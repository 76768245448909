import React from "react";
import { Chatbot, createChatBotMessage } from "react-chatbot-kit";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Config from "./Config";
import "./app.css";
import Data from "./data.json";
import ChatBotMessage from "./ChatBotMessage";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  const [message, setMessages] = React.useState("");
  const [botMessages, setBotMessages] = useState([
    {
      message: `<p><span style="font-size:14px">مرحبا! أنا دندن روبوت دردشة من ركن العائلة أعمل بتقنية الذكاء الاصطناعي.</span></p>
      <p>
      <p style="font-size:12px">هذه الخدمة تتيح لك إجراء محادثات مشابهة الحوارات البشرية بكل ما يخص العناية بالأطفال من عمر 0 إلى 8 سنوات.&nbsp;</p>
      <span style="font-size:12px">ما يمكن أن أقدمه لك؟</span>
      <ul style="font-size:12px">
        <li>ارشادات تربوية مُفصّلة لاحتياجات طفلك: نصائح مُختصة للتحديات الخاصة التي تواجهك أنت و طفلك (مثال: <a href="#msg=ابنتي عمرها ٣ سنوات و متعلقة بي ولا تقبل اي احد" >ابنتي عمرها ٣ سنوات و متعلقة بي ولا تقبل اي احد"</a>&rdquo;)</li>
        <li>أنشطة: أفكار لأنشطة مُصممة خصيصًا لعمر طفلك ومرحلته التنموية &nbsp;(&ldquo;<a href="#msg=ابني عمره ٦ سنوات و يحب الرسم والطبخ. ارسل لي نشاط ممتع">ابني عمره ٦ سنوات و يحب الرسم والطبخ. ارسل لي نشاط ممتع</a>&rdquo;)</li>
        <li>موارد تعليمية: ا أفضل الموارد التعليمية المقدمة لكم من برنامج أهلا سمسم (مثال &ldquo;<a href="#msg=أرسل لي بطاقات تعليمية عن المشاعر">أرسل لي بطاقات تعليمية عن المشاعر</a>&rdquo;)</li>
      </ul>
      <span style="font-size:12px">ملاحظات:</span>
      <ul style="font-size:12px">
        <li>أنا روبوت دردشة هنا لأقدّم لك الدعم والارشاد</li>
        <li>أنني دائما أهدف إلى تقديم معلومات دقيقة ومفيدة، ولكن تذكر أنه يجب عليك أن تأخذ في الاعتبار ظروفك الخاصة، وعند الشك، يُفضل استشارة مختص.</li>
        <li>يُرجى العلم أننا لا نتحمل المسؤولية عن أي معلومات غير دقيقة قد يتم تقديمها، حيث أنني أعمل بنظام أتوماتيكي وقد لا تكون المعلومات دقيقة دائمًا.</li>
      </ul>
      <span style="font-size:12px">كيفية التفاعل معي</span>
      <span style="font-size:12px">فقط اكتب سؤالك أو الموضوع الذي تهتم به بالتفصيل، وسأبذل قصارى جهدي لتزويدك بالمعلومات والدعم ذو الصلة. كل ما زودتني بتفاصيل أكثر يمكنني مساعدتك بشكل أنسب.&nbsp;</span>
      <p style="font-size:12px">لننطلق معًا في رحلة تربويّة ممتعة&nbsp;</p>
      </p>  
      `,
      sender: "bot",
      loading: false,
      links: [],
      isWelcomeMessage: true,
    },
  ]);

  const handleUserInput = async () => {
    // check if message is empty
    if (message === "") return;
    let msgs = [...botMessages];
    let newMessage = [
      {
        message: message,
        sender: "user",
        loading: false,
        links: [],
      },
      {
        message: "جاري التحميل ...",
        sender: "bot",
        loading: true,
        links: [],
      },
    ];
    setBotMessages([...botMessages, ...newMessage]);
    msgs = [...botMessages, ...newMessage];
    setMessages("");

    const response = await fetch("https://www.chatbase.co/api/v1/chat", {
      method: "POST",
      headers: {
        Authorization: "Bearer cab63279-45d6-414e-a466-827ceb0367f3",
      },
      body: JSON.stringify({
        messages: [{ content: message, role: "user" }],
        chatbotId: "zrfKuwGj0v1GvgncfJdmZ",
        stream: false,
        temperature: 0,
        model: "gpt-3.5-turbo",
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw Error(errorData.message);
    }

    const data = JSON.parse(await response.text());

    if (!data) {
      // error happened
    }

    // get message from botMessages if it's loading
    let loadingMessage = msgs.find((msg) => msg.loading === true);
    if (!loadingMessage) return;
    loadingMessage.loading = false;
    loadingMessage.message = data.text;

    // get all links {url:,type:"pdf/doc/mp4..."} form any type
    //data,text ex: "text": "مرحبًا! يمكنك الاستماع إلى صوت حرف (م) بالمد الطويل من خلال الفيديو التالي: <a href=\"https://apis.ircascms.com/public/1692623844819_.mp4\" rel=\"noopener noreferrer\" target=\"_blank\">فيديو الصوت الطويل لحرف الميم</a>. استمتع بالاستماع! هل يمكنني مساعدتك بشيء آخر؟"

    const links = data.text.match(
      /https:\/\/apis.ircascms.com\/public\/[0-9]+_.[a-z0-9]+/gi
    );
    if (links) {
      loadingMessage.links = links.map((link) => ({
        type: link.split(".").pop(),
        link: link,
      }));
    }
    // set loadingMessage to botMessages
    setBotMessages(msgs);
    // scroll to bottom chatbotContainer
    const chatbotContainer = document.querySelector(".chatbotContainer");
    chatbotContainer.scrollTop = chatbotContainer.scrollHeight;
  };

  useEffect(() => {
    // scroll to bottom chatbotContainer
    const chatbotContainer = document.querySelector(".chatbotContainer");
    chatbotContainer.scrollTop = chatbotContainer.scrollHeight;
  }, []);

  useEffect(() => {
    const handleHistoryChange = () => {
      // Your code to run when window.history changes
      const url = window.location.href;
      const msg = url.split("#msg=")[1];
      if (msg) { 
        let msgTemp = msg;
        //convert msg to unicode utf-8
        msgTemp = decodeURIComponent(msgTemp);
        //set msg to input
        setMessages(msgTemp);
        //sendBtn click
        setTimeout(() => {
          const sendBtn = document.getElementById("sendBtn");
          sendBtn.click();
        }, 500);
        //remove #msg from url
        window.history.pushState("", "", "/");
      }
    };

    // Add event listener for popstate
    window.addEventListener("popstate", handleHistoryChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("popstate", handleHistoryChange);
    };
  }, []); // Empty dependency array ensures that the effect runs only once, similar to componentDidMount

  return (
    <>
      <div className="app">
        <div className="app__header">
          <div className="logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="152"
              height="82"
              fill="none"
            >
              <path
                fill="#6855E4"
                d="M3.682 57.958c-.55 0-1.072-.038-1.565-.114a8.54 8.54 0 0 1-1.166-.256l.483-3.044c.228.038.446.066.655.085.208.038.417.057.626.057.72 0 1.232-.209 1.536-.626.322-.417.484-1.09.484-2.02V40.261h3.897v12.433c0 1.044-.142 1.916-.426 2.618-.266.702-.636 1.242-1.11 1.622a3.72 3.72 0 0 1-1.593.796 7.26 7.26 0 0 1-1.821.228Zm13.043-8.166c.93 0 1.612-.19 2.048-.569.437-.398.655-1.09.655-2.076v-.74c0-.968-.323-1.698-.968-2.191-.645-.512-1.565-.768-2.76-.768h-2.702V40.26h3.47c1.177 0 2.192.142 3.045.427.872.266 1.584.645 2.134 1.138a4.475 4.475 0 0 1 1.252 1.736 5.43 5.43 0 0 1 .427 2.162v1.878c0 1.897-.39 3.272-1.167 4.125-.759.835-2.067 1.252-3.926 1.252h-7.028v-3.187h5.52Zm16.7 1.622c0 .55-.019.949-.057 1.195-.019.247-.057.37-.114.37-.872 0-1.716-.057-2.532-.17a7.993 7.993 0 0 1-2.219-.683c-.645-.342-1.148-.797-1.508-1.366-.36-.588-.54-1.3-.54-2.134V32.067h3.897v15.307c0 .455.057.854.171 1.195.133.322.313.579.54.768.247.17.513.294.797.37.285.057.579.085.882.085.247 0 .418.133.512.399.114.265.171.673.171 1.223Zm-.167 1.565c-.474 0-.834-.123-1.081-.37-.265-.265-.398-.645-.398-1.138 0-.512.085-.92.256-1.223.152-.304.389-.456.711-.456h8.365c.986 0 1.678-.208 2.077-.626.417-.417.626-1.09.626-2.02v-.74c0-1.972-1.167-2.958-3.5-2.958H34.14V40.26l7.739-8.194 2.646 2.447-5.52 5.747h2.276c1.12 0 2.087.142 2.903.427.815.266 1.479.645 1.991 1.138.531.474.92 1.053 1.167 1.736.246.663.37 1.384.37 2.162v1.878c0 1.915-.39 3.29-1.167 4.125-.778.835-2.087 1.252-3.926 1.252h-9.36Z"
              ></path>
              <path
                fill="#6855E4"
                fill-rule="evenodd"
                d="m115.354 1.802 32.012 23.837a9.103 9.103 0 0 1 3.186 10.228L137.02 75.76a9.104 9.104 0 0 1-8.621 6.181h-36.96a9.104 9.104 0 0 1-8.649-6.25L69.61 35.794a9.103 9.103 0 0 1 3.168-10.127l31.671-23.838a9.103 9.103 0 0 1 10.91-.027h-.004Z"
                clip-rule="evenodd"
              ></path>
              <mask
                id="a"
                width="83"
                height="82"
                x="69"
                y="0"
                maskUnits="userSpaceOnUse"
                style={{ maskType: "alpha" }}
              >
                <path
                  fill="#7364DB"
                  fill-rule="evenodd"
                  d="m115.354 1.802 32.012 23.837a9.103 9.103 0 0 1 3.186 10.228L137.02 75.76a9.104 9.104 0 0 1-8.621 6.181h-36.96a9.104 9.104 0 0 1-8.649-6.25L69.61 35.794a9.103 9.103 0 0 1 3.168-10.127l31.671-23.838a9.103 9.103 0 0 1 10.91-.027h-.004Z"
                  clip-rule="evenodd"
                ></path>
              </mask>
              <g mask="url(#a)">
                <circle
                  cx="121.36"
                  cy="18.477"
                  r="23.297"
                  fill="#AECF55"
                ></circle>
                <rect
                  width="14.46"
                  height="14.46"
                  x="89.227"
                  y="30.745"
                  fill="#F79AFF"
                  rx="3.213"
                  transform="rotate(45 89.227 30.745)"
                ></rect>
                <path
                  fill="#FFC800"
                  d="M110.169 49.917c4.086-2.663 9.534.018 9.916 4.881l2.951 37.564c.39 4.967-4.775 8.469-9.245 6.27L79.273 81.64c-4.47-2.2-4.845-8.43-.671-11.15l31.567-20.574Z"
                ></path>
              </g>
            </svg>
          </div>
          <div>Caregivers Chatbot</div>
        </div>
        <div className="App mx-auto col-md-6 col-sm-8 col-lg-4 cpl-xl-3 my-5">
          <div className="chatbotContainer">
            {botMessages.map((message) => (
              <ChatBotMessage message={message} />
            ))}
          </div>
          <div
            className={
              botMessages.find((msg) => msg.loading === true)
                ? "react-chatbot-kit-chat-input-container loadingBorder"
                : "react-chatbot-kit-chat-input-container"
            }
          >
            <input
              className="react-chatbot-kit-chat-input"
              placeholder="اكتب رسالتك هنا"
              onChange={(e) => setMessages(e.target.value)}
              value={message}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleUserInput();
                }
              }}
              autoFocus
            />
            <button
              className="react-chatbot-kit-chat-btn-send"
              onClick={handleUserInput}
              id="sendBtn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="react-chatbot-kit-chat-btn-send-icon"
              >
                <path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
